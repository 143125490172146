<template>
  <div class="checkOut t-left">
    <h2>{{ item.title }} - Garderobenmarke</h2>
    <div style="clear: both;" />
    <hr class="separator">
    <div class="t-right">
      <p>Preis: {{ item.gaderobePrice.toFixed(2) }}€</p>
    </div>
    <hr class="separator">
    <payment-type :amount="item.gaderobePrice" @total="credits => {useCredits = credits}" />
    <hr class="separator">
    <p style="font-size: 0.8em;">
      Mit dem klicken auf "Kaufen" bestätige ich, dass ich die <a class="text-primary" href="https://nachtschicht-kaiserslautern.app/agb/" target="_blank">AGB</a> gelesen habe und diese akzeptiere.
    </p>
    <div style="margin: 6px 0">
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <button class="bg-primary button color-white fluid" type="button" @click="checkOut" :disabled="!readyState" :class="{disabled: !readyState}">
        Kaufen
      </button>
      <button class="bgcolor-black button color-white fluid" style="margin-top: 16px; margin-bottom: 80px;" @click="$emit('close')">
        Abbrechen
      </button>
    </div>
  </div>
  <loader size="small" v-if="loading" />
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import PaymentType from "@/components/payment/paymentType";

export default {
  name: "garderobe",
  components: {Loader, PaymentType},
  props: {
      item: {
          type: Object,
          required: true
      },
      readyState: {
          type: Boolean,
          required: true
      },
      userInfo: {
          type: Object,
          required: false
      }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      loading: false,
      error: '',
      useCredits: 0,
    }
  },
  mounted() {
  },
  methods: {
    checkOut(){
      this.loading = true
      this.error = ''

      aSyncData('checkout/garderobe', {
        eventId: this.item.id,
        organizerId: this.item.eventOwner,
        gaderobePrice: this.item.gaderobePrice,
        useCredits: this.useCredits
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
                console.log(r.data.link)
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }else if(r.status === 285){
              this.error = 'Kauf noch nicht abgeschlossen'
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false

          })
    },
  }
}
</script>

<style scoped>
.smallHint{
  font-size: 0.8em;
}


#chargeInput {
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}

.chargeInput-wrapper{
  margin: 8px 0;
  display: flex;
  align-items: baseline;
  column-gap: 12px;
  justify-content: center;
}

.chargeInput-wrapper #chargeInput {
  width: 40%;
}

.selectionButtons{
  column-gap: 12px;
}

.selectionButtons button{
  margin-top: 8px;
  padding: 12px;
  border-radius: 10px;
  width: 100%;
}

.selectionButtons button:hover{
  background-color: #2068ff !important;
  opacity: 1 !important;
}
.separator{
  opacity: 0.1;
  margin: 16px 0;
}

.button{
  margin-top: 12px;
}
</style>