<template>
  <main class="!p-0">
    <div class="relative">
      <div class="relative w-full h-[500px] bg-center bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/loungesOverviewBackground.jpg')">
        <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
          <!-- Ihr Inhalt hier -->
        </div>
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 md:w-auto">
        <h3 class="text-center text-4xl text-primary font-bold">FAQ</h3>
      </div>
    </div>
    <div class="px-6 md:max-w-screen-sm md:m-auto pt-12">
      <div class="bg-gray-950 rounded-lg py-6 px-6 text-center cursor-pointer transition">
        <div class="" id="question" @click="toggleLostTicketAnswer">
          <div class="flex justify-center">
            <div class="bg-primary rounded-full w-16 h-16 p-4">
              <img src="/assets/images/icons/ticket-icon.svg" alt="" class="w-full">
            </div>
          </div>
          <h5 class=" text-xl pt-6">Ich habe kein Ticket erhalten</h5>
        </div>
        <div v-if="showLostTicketAnswer" class="text-left pt-4">
          <h3 class="text-2xl pb-2">Ich habe kein Ticket erhalten.<br>Was kann ich tun?</h3>
          <p class="text-gray-300">Solltest du dein Ticket nach der Bestellung nicht erhalten haben, kann dies verschiedene Gründe haben.</p>

          <h3 class="text-xl pb-2 pt-6">Check deinen Spam Ordner</h3>
          <p class="text-gray-300">Ist unsere Mail fälschlicher Weise in deinen Spam Ordner gerutscht?</p>

          <h3 class="text-xl pb-2 pt-6">Deine Zahlung wurde abgebrochen oder konnte nicht abgebucht werden</h3>
          <p class="text-gray-300">Prüfe, ob der Betrag von deinem Konto wirklich abgebucht wurde.</p>

          <h3 class="text-xl pb-2 pt-6">Du hast die falsche E-Mail Adresse angegeben</h3>
          <p class="text-gray-300">Das kann durchaus passieren! Leider kann dir dabei nur unser Support helfen.</p>

          <h3 class="text-2xl pb-2 pt-6">Hat nichts geholfen?<br>Dann hilft dir unser Support.</h3>
          <router-link to="lostticket" class="">
            <button class="mt-4 w-full md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:primaryprimary text-md hover:text-primary transition">
              Ticket erneut zusenden / Runterladen
            </button>
          </router-link>
          <div class="p-4"></div>
          <p class="text-gray-300">Du erreichst uns telefonisch unter</p>
          <p class="text-purple-300"><a href="tel:+496313105759">+49 6 31 / 3 10 57 59</a></p>
          <div class="p-4"></div>
          <p class="text-gray-300">oder per Mail unter</p>
          <p class="text-purple-300"><a href="mailto:info@nachtschicht-kaiserslautern.de">info@nachtschicht-kaiserslautern.de</a></p>
          <div class="p-4"></div>
          <h5 class="text-lg ">Bitte gib uns folgende Informationen durch:</h5>
          <ul class="list-disc px-4 pt-2">
            <li class="text-gray-300">Link zur Eventseite der gekauften Tickets</li>
            <li class="text-gray-300">Name & Datum der Veranstaltung</li>
            <li class="text-gray-300">Angegebene E-Mail-Adresse</li>
            <li class="text-gray-300">Angegebenen Vor-und Nachnamen</li>
          </ul>
          <div class="p-4"></div>
          <button @click="toggleLostTicketAnswer" class="mt-4 w-full md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:border-primary text-md hover:text-primary transition">
            Info schließen
          </button>
        </div>
      </div>
      <div class="p-6"></div>
      <div v-for="(question, index) in faqList" :key="index" class="bg-gray-950 rounded-lg mt-4">
        <div @click="toggleFAQ(index)" :class="[question.open ? 'bg-primary text-white' : 'bg-gray-950', 'uppercase font-semibold p-6 rounded-t-lg cursor-pointer hover:bg-primary transition']">
          <h5>{{question.question}}</h5>
        </div>
        <div class="p-6 !pt-6" v-if="question.open">
          <p class="text-gray-300" v-html="question.answer"></p>
          <div v-show="fundgrubeButton" class="p-4 text-center">
            <router-link to="/">
              <button class="w-[250px] md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:botext-primary text-md hover:text-primary transition">
                Fundgrube öffnen
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center py-6">
        <h5 class="text-lg">Du hast weitere Frage?</h5>
        <h5 class="text-lg"><a href="mailto:info@nachtschicht-kaiserslautern.de" class="text-primary/70">info@nachtschicht-kaiserslautern.de</a> hilft dir weiter!</h5>
      </div>
    </div>
    <div class="md:p-12"></div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";
import {aSyncData, stringToDate} from "@/modules/main";
import LoungesView from "@/components/lounge/lounges";

export default {
  name: "FaqView",
  title: "FAQ",
  props: ["lounge"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      showLostTicketAnswer: false,
      faqList: [
        {
          question:'Wie und wann kann ich euch telefonisch erreichen?',
          answer:'Wir sind unter der Telefonnummer: 0631-3105759 erreichbar. <br>time to catch us: <br>mittwochs 09:00 - 12:00 Uhr<br> freitags und samstags ab 22:00 Uhr',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Wie kann ich einen Bungalow reservieren?',
          answer:'Einfach auf unserer Homepage das Register "Reservierung" anklicken und den einfachen Schritten folgen.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Wie sind die Öffnungstage und -zeiten',
          answer:'Freitag ab 22 Uhr <br/> Samstag ab 22 Uhr <br/> Tag vor einem gesetzlichen Feiertag ab 22 Uhr',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Welche Musikrichtung wird in den Bereichen gespielt?',
          answer:'Freitag: Hip Hop music im Agostea /  90er, 2000er & Partyhits in der Mausefalle<br> Samstag: House music im Agostea / Hip hop music im La Vie / 90er, 2000er & Partyhits in der Mausefalle <br>*An Sonderöffnungstagen kann das musikalische Programm abweichen',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Kann ich vor 24:00 Uhr rein wenn ich um 24:00 Uhr 18 Jahre alt werde?',
          answer:'Klar',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Wo finde ich das U18 Formular?',
          answer:'<a href="https://nachtschicht-kaiserslautern.app/muttizettel">https://nachtschicht-kaiserslautern.app/muttizettel</a>',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Für wie viele U18 Personen kann ein 18-Jähriger Aufsicht machen?',
          answer:'Eine Aufsichtsperson kann jeweils nur für EINEN U18-jährigen die Aufsicht übernehmen.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Warum muss ich beim Ausgang immer 4,-€ oder 6,-€ bezahlen?',
          answer:'Das ist der Mindestverzehr. Das bedeutet wenn kein Getränk verzehrt wird, sind trotzdem 4,- € (Samstags 6,-€) zu entrichten. Wird mindestens ein Getränk verzehrt, verrechnen sich automatisch 4,- € bzw. 6,- € mit dem verzehrten Getränk.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Wann kann ich Gutscheine einlösen?',
          answer:'An allen gewöhnlichen Öffnungstagen BIS 24:00 Uhr an der Kasse. Es kann immer nur ein Gutschein pro Gast und pro Nacht eingelöst werden.',
          open: false,
          fundgrubeButton: true,
        },

      ]
    }
  },
  methods: {
    toggleLostTicketAnswer() {
      this.showLostTicketAnswer = !this.showLostTicketAnswer;
    },
    toggleFAQ(index) {
      this.faqList[index].open = !this.faqList[index].open;
    }

  }
}
</script>